import * as React from "react";
// import 'normalize.css'
import "../styles/shared.module.css";
import "../styles/layout.css";

function App({ Component, pageProps }) {
	return <Component {...pageProps} />;
}

export default App;
